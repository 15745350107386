import loginSvg from '../assets/vBankPJAssets/LoginSVG.svg';
import smallColoredLogo from '../assets/vBankPJAssets/vBankSmallLogo.svg';
import smallWhiteLogo from '../assets/vBankPJAssets/vBankSmallLogoWhite.svg';
import backgroundLogo from '../assets/vBankPJAssets/backgroundLogo.svg';
import tokenImageSvg from '../assets/vBankPJAssets/tokenImage.svg';
import faviconVbank from '../assets/vBankPJAssets/favicon/favicon.ico';
//vbank
import loginSvgAmazon from '../assets/amazonBankAssets/LoginSVG.svg';
import smallColoredLogoAmazon from '../assets/amazonBankAssets/amazonBankGreenLogo.svg';
import smallWhiteLogoAmazon from '../assets/amazonBankAssets/amazonBankLogo.svg';
import backgroundLogoAmazon from '../assets/amazonBankAssets/backgroundLogo.svg';
import tokenImageSvgAmazon from '../assets/amazonBankAssets/tokenImage.svg';
import faviconAmazon from '../assets/amazonBankAssets/favicon/favicon.ico';
//amazonbank
import loginSvgAcium from '../assets/aciumAssets/loginSvgAcium.svg';
import smallColoredLogoAcium from '../assets/aciumAssets/smallColoredLogoAcium.svg';
import smallWhiteLogoAcium from '../assets/aciumAssets/smallWhiteLogoAcium.svg';
import backgroundLogoAcium from '../assets/aciumAssets/backgroundLogoAcium.svg';
import tokenImageAcium from '../assets/aciumAssets/tokenImageAcium.svg';
import faviconAcium from '../assets/aciumAssets/favicon/favicon.ico';
//acium
import loginSvgAprobank from '../assets/aprobankAssets/loginSvgAprobank.svg';
import smallColoredLogoAprobank from '../assets/aprobankAssets/smallColoredLogoAprobank.svg';
import smallWhiteLogoAprobank from '../assets/aprobankAssets/smallWhiteLogoAprobank.svg';
import backgroundLogoAprobank from '../assets/aprobankAssets/backgroundLogoAprobank.svg';
import tokenImageAprobank from '../assets/aprobankAssets/tokenImageAprobank.svg';
import faviconAprobank from '../assets/aprobankAssets/favicon/favicon.ico';
//aprobank

import loginSvgBelobank from '../assets/belobankAssets/loginSvgBelobank.svg';
import smallColoredLogoBelobank from '../assets/belobankAssets/smallColoredLogoBelobank.svg';
import smallWhiteLogoBelobank from '../assets/belobankAssets/smallWhiteLogoBelobank.svg';
import backgroundLogoBelobank from '../assets/belobankAssets/backgroundLogoBelobank.svg';
import tokenImageBelobank from '../assets/belobankAssets/tokenImageBelobank.svg';
import faviconBelobank from '../assets/belobankAssets/favicon/favicon.ico';
//belobank

import loginSvgSimer from '../assets/simerAssets/loginSvgSimer.svg';
import smallColoredLogoSimer from '../assets/simerAssets/smallColoredLogoSimer.svg';
import smallWhiteLogoSimer from '../assets/simerAssets/smallWhiteLogoSimer.svg';
//import backgroundLogoSimer from '../assets/simerAssets/backgroundLogoSimer.svg';
import tokenImageSimer from '../assets/simerAssets/tokenImageSimer.svg';
import faviconSimer from '../assets/simerAssets/favicon/favicon.ico';
//simerbank

export const APP_CONFIG = {
	vbank: {
		titleLogin: 'V Bank',
		name: 'V BANK - Dashboard do Banco V Bank',
		description: 'IntegraPAY – Soluções em pagamento para o seu negócio',
		crispId: '19e9aab6-292f-406c-8268-e45ea1a1dda3',
		sidebarRede: 'Rede VBank',
		AbaCartoes: true,
		linkApp: 'https://play.google.com/store/apps/details?id=br.com.pj.vbank',
		linkDePagamento: 'https://banco.vbank.integrapay.com.br',
		linkPdfTermoContrato:
			'https://sandbox.fitbank.integrapay.com.br/termos/Termos_abertura_conta_digital_AciumBnk.pdf',
		/* mailSupport: 'contato@integrapay.com.br', */

		mainCollors: {
			primary: '#4C4B97',
			primaryVariant: '#7776BC',
			secondary: '#9D9DC6',
			backgrounds: '#ECECF4',
			disabledTextfields: '#E0DFF8',
			extratoHome: '#302F60',
			primaryGradient:
				'linear-gradient(135deg, rgba(2,149,59,1) 15%, rgba(75,75,150,1) 100%)',
			secondaryGradient:
				'linear-gradient(360deg, rgba(2,149,59,1) 0%, rgba(75,75,150,1) 100%)',
			buttonGradient:
				'linear-gradient(135deg, rgba(2,149,59,1) 10%, rgba(75,75,150,1) 100%)',
			buttonGradientVariant:
				'linear-gradient(180deg, rgba(2,149,59,1) 15%, rgba(75,75,150,1) 100%)',
			drawerSideBar: '#02953b',
		},
		theme: {
			typography: {
				fontFamily: 'Montserrat-Regular',
				/* fontWeight: 'bold', */

				h1: {
					fontFamily: 'Montserrat-SemiBold',
					fontSize: '16px',
				},
				subtitle1: {
					fontFamily: 'Montserrat-Regular',
					fontSize: '16px',
				},
				subtitle2: {
					fontFamily: 'Montserrat-Regular',
					fontSize: '16px',
				},
				h4: {
					fontFamily: 'Montserrat-SemiBold',
				},
			},
			palette: {
				background: {
					default: '#fff',
					paper: '#FFF',
				},
				primary: {
					main: '#4C4B97',
					light: '#EDEDF4',
				},
				secondary: {
					main: '#fff',
					light: '#fff',
				},
				tertiary: {
					main: '#fff',
					light: '#fff',
				},
			},
			overrides: {
				MuiInputBase: {
					input: {
						fontFamily: 'Montserrat-Regular',
						/* fontWeight: 'bold', */
						color: '#4C4B97',
					},
					label: {
						color: 'white',
					},
					placeholder: {
						color: 'white',
					},
				},

				MuiTableContainer: {
					root: {
						overflowX: 'unset',
					},
				},

				MuiOutlinedInput: {
					root: {
						height: '45px',
						borderColor: 'white',
						borderRadius: 27,
						'&$cssFocused $notchedOutline': {
							borderWidth: 1,
						},
						'&:not($error) $notchedOutline': {
							borderColor: '#4C4B97',

							// Reset on touch devices, it doesn't add specificity
							'@media (hover: none)': {
								borderColor: 'rgba(0, 0, 0, 0.23)',
							},
						},

						borderWidth: '1px',
						'& :-webkit-autofill': {
							'-webkit-padding-after': '15px',
							'-webkit-padding-before': '18px',
							'-webkit-padding-end': '15px',
							'-webkit-padding-start': '15px',
							'-webkit-background-clip': 'text',
							'-webkit-color': 'white',

							'-webkit-text-fill-color': 'white !important',
						},

						'& $notchedOutline': {
							borderColor: 'white',
							borderWidth: 1,
						},
						'&:hover $notchedOutline': {
							borderColor: 'white',
							borderWidth: 1,
						},
						'&$focused $notchedOutline': {
							borderColor: 'white',
							borderWidth: 1,
						},
					},
					focused: {
						borderWidth: '1px',
					},
					notchedOutline: {
						borderWidth: '1px',
					},
					input: {
						'&::placeholder': {
							fontFamily: 'Montserrat-SemiBold',
							textOverflow: 'ellipsis !important',
							color: 'black',
							/* fontWeight: '600', */
							fontSize: '23px',
						},

						borderRadius: '27px',
						height: '10px',
						color: '#4C4B97',
						fontFamily: 'Montserrat-Thin',
						fontWeight: 'bold',
					},
				},

				MuiTextField: {
					root: {
						margin: '0px 0px 0px 0px',
					},
				},
				MuiInputLabel: {
					outlined: {
						transform: 'translate(14px, 15px) scale(1)',
						'&$shrink': {
							transform: 'translate(14px, -20px) scale(0.8)',
							color: '#4C4B97',
							fontFamily: 'Montserrat-SemiBold',
						},
						fontFamily: 'Montserrat-Thin',
						fontWeight: 'bold',
						color: '#4C4B97',
					},
					root: {
						transform: 'translate(10px, 12px) scale(1)',
						'&$shrink': {
							transform: 'translate(10px, -2px) scale(0.8)',
							color: '#4C4B97',
							fontFamily: 'Montserrat-SemiBold',
						},
						fontFamily: 'Montserrat-Thin',
						fontWeight: 'bold',
						color: '#4C4B97',
					},
				},
				MuiButton: {
					contained: {
						fontFamily: 'Montserrat-Thin',
						/* fontSize: '0.9rem', */
						textTransform: 'none',
						boxShadow: '0px 0px 0px 0px',
					},

					fontFamily: 'Montserrat-Thin',
					fontWeight: 'bold',
				},
				MuiStepIcon: {
					color: 'red',
					fill: 'red',
					active: {
						color: 'red',
						fill: 'red',
					},
					completed: {
						color: 'red',
						fill: 'red',
					},
				},
			},
		},

		cssVariables: {
			gradient: {
				main: 'linear-gradient(to right top, #cc9b00, #cc9b00);',
			},
		},
		assets: {
			loginSvg: loginSvg,
			smallColoredLogo: smallColoredLogo,
			smallWhiteLogo: smallWhiteLogo,
			backgroundLogo: backgroundLogo,
			tokenImageSvg: tokenImageSvg,
			favicon: faviconVbank,
		},
	},

	amazonbank: {
		titleLogin: 'Amazon Bank',
		name: 'Amazon Bank - Dashboard do Banco Amazon Bank',
		description: 'IntegraPAY – Soluções em pagamento para o seu negócio',
		crispId: '7388cd2c-c4d0-4db6-988d-0f27075c291b',
		sidebarRede: 'Rede Amazon Bank',
		AbaCartoes: false,
		linkApp:
			'https://play.google.com/store/apps/details?id=br.com.pj.amazonbank',
		linkDePagamento: 'https://banco.amazonbankingtrust.com.br',
		linkPdfTermoContrato:
			'https://sandbox.fitbank.integrapay.com.br/termos/Termos_abertura_conta_digital_AmazonBank.pdf',
		/* mailSupport: 'contato@integrapay.com.br', */

		mainCollors: {
			primary: '#0C4727',
			primaryVariant: '#299359',
			secondary: '#90BAA3',
			backgrounds: '#E7EDEA',
			disabledTextfields: '#90BAA3',
			extratoHome: '#123E26',
			primaryGradient:
				'linear-gradient(135deg, rgba(215,162,31,1) 15%, rgba(170,122,19,1) 100%)',
			secondaryGradient:
				'linear-gradient(360deg, rgba(215,162,31,1) 0%, rgba(170,122,19,1) 100%)',
			buttonGradient:
				'linear-gradient(135deg, rgba(215,162,31,1) 10%, rgba(170,122,19,1) 100%)',
			buttonGradientVariant:
				'linear-gradient(180deg, rgba(215,162,31,1) 15%, rgba(170,122,19,1) 100%)',
			drawerSideBar: '#d7a21f',
		},
		theme: {
			typography: {
				fontFamily: 'Montserrat-Regular',
				/* fontWeight: 'bold', */
				h1: {
					fontFamily: 'Montserrat-SemiBold',
					fontSize: '16px',
				},
				subtitle1: {
					fontFamily: 'Montserrat-Regular',
					fontSize: '16px',
				},
				subtitle2: {
					fontFamily: 'Montserrat-Regular',
					fontSize: '16px',
				},
				h4: {
					fontFamily: 'Montserrat-SemiBold',
				},
			},
			palette: {
				background: {
					default: '#fff',
					paper: '#FFF',
				},
				primary: {
					main: '#0C4727',
					light: '#EDEDF4',
				},
				secondary: {
					main: '#fff',
					light: '#fff',
				},
				tertiary: {
					main: '#fff',
					light: '#fff',
				},
			},
			overrides: {
				MuiInputBase: {
					input: {
						fontFamily: 'Montserrat-Regular',
						/* fontWeight: 'bold', */
						color: '#0C4727',
					},
					label: {
						color: 'white',
					},
					placeholder: {
						color: 'white',
					},
				},

				MuiTableContainer: {
					root: {
						overflowX: 'unset',
					},
				},

				MuiOutlinedInput: {
					root: {
						height: '45px',
						borderColor: 'white',
						borderRadius: 27,
						'&$cssFocused $notchedOutline': {
							borderWidth: 1,
						},
						'&:not($error) $notchedOutline': {
							borderColor: '#0C4727',

							// Reset on touch devices, it doesn't add specificity
							'@media (hover: none)': {
								borderColor: 'rgba(0, 0, 0, 0.23)',
							},
						},

						borderWidth: '1px',
						'& :-webkit-autofill': {
							'-webkit-padding-after': '15px',
							'-webkit-padding-before': '18px',
							'-webkit-padding-end': '15px',
							'-webkit-padding-start': '15px',
							'-webkit-background-clip': 'text',
							'-webkit-color': 'white',

							'-webkit-text-fill-color': 'white !important',
						},

						'& $notchedOutline': {
							borderColor: 'white',
							borderWidth: 1,
						},
						'&:hover $notchedOutline': {
							borderColor: 'white',
							borderWidth: 1,
						},
						'&$focused $notchedOutline': {
							borderColor: 'white',
							borderWidth: 1,
						},
					},
					focused: {
						borderWidth: '1px',
					},
					notchedOutline: {
						borderWidth: '1px',
					},
					input: {
						'&::placeholder': {
							fontFamily: 'Montserrat-SemiBold',
							textOverflow: 'ellipsis !important',
							color: 'black',
							/* fontWeight: '600', */
							fontSize: '23px',
						},

						borderRadius: '27px',
						height: '10px',
						color: '#0C4727',
						fontFamily: 'Montserrat-Thin',
						fontWeight: 'bold',
					},
				},

				MuiTextField: {
					root: {
						margin: '0px 0px 0px 0px',
					},
				},
				MuiInputLabel: {
					outlined: {
						transform: 'translate(14px, 15px) scale(1)',
						'&$shrink': {
							transform: 'translate(14px, -20px) scale(0.8)',
							color: '#0C4727',
							fontFamily: 'Montserrat-SemiBold',
						},
						fontFamily: 'Montserrat-Thin',
						fontWeight: 'bold',
						color: '#0C4727',
					},
					root: {
						transform: 'translate(10px, 12px) scale(1)',
						'&$shrink': {
							transform: 'translate(10px, -2px) scale(0.8)',
							color: '#0C4727',
							fontFamily: 'Montserrat-SemiBold',
						},
						fontFamily: 'Montserrat-Thin',
						fontWeight: 'bold',
						color: '#0C4727',
					},
				},
				MuiButton: {
					contained: {
						fontFamily: 'Montserrat-Thin',
						/* fontSize: '0.9rem', */
						textTransform: 'none',
						boxShadow: '0px 0px 0px 0px',
					},

					fontFamily: 'Montserrat-Thin',
					fontWeight: 'bold',
				},
				MuiStepIcon: {
					color: 'red',
					fill: 'red',
					active: {
						color: 'red',
						fill: 'red',
					},
					completed: {
						color: 'red',
						fill: 'red',
					},
				},
			},
		},

		cssVariables: {
			gradient: {
				main: 'linear-gradient(to right top, #cc9b00, #cc9b00);',
			},
		},
		assets: {
			loginSvg: loginSvgAmazon,
			smallColoredLogo: smallColoredLogoAmazon,
			smallWhiteLogo: smallWhiteLogoAmazon,
			backgroundLogo: backgroundLogoAmazon,
			tokenImageSvg: tokenImageSvgAmazon,
			favicon: faviconAmazon,
		},
	},

	acium: {
		titleLogin: 'Acium BNK Empresas',
		name: 'ACIUM - Dashboard do Banco ACIUM',
		description: 'IntegraPAY – Soluções em pagamento para o seu negócio',
		crispId: '975a0211-1a18-4265-b253-df495ee372e6',
		sidebarRede: 'Rede Acium BNK',
		AbaCartoes: false,
		linkApp:
			'https://play.google.com/store/apps/details?id=br.com.pj.aciumbnk',
		linkDePagamento: 'https://banco.aciumbnk.com.br',
		linkPdfTermoContrato:
			'https://sandbox.fitbank.integrapay.com.br/termos/Termos_abertura_conta_digital_AciumBnk.pdf',
		/* mailSupport: 'contato@integrapay.com.br', */

		mainCollors: {
			primary: '#3C3C3C',
			primaryVariant: '#262626',
			secondary: '#070707',
			backgrounds: '#F4F4F4',
			disabledTextfields: '#E9E9E9',
			extratoHome: '#2B2B2B',
			primaryGradient:
				'linear-gradient(135deg, rgba(60,60,60) 15%, rgba(7,7,7) 100%)',
			secondaryGradient:
				'linear-gradient(360deg, rgba(60,60,60) 0%, rgba(7,7,7) 100%)',
			buttonGradient:
				'linear-gradient(135deg, rgba(60,60,60) 10%, rgba(7,7,7) 100%)',
			buttonGradientVariant:
				'linear-gradient(180deg, rgba(60,60,60) 15%, rgba(7,7,7) 100%)',
			drawerSideBar: '#3C3C3C',
		},
		theme: {
			typography: {
				fontFamily: 'Montserrat-Regular',
				/* fontWeight: 'bold', */
				h1: {
					fontFamily: 'Montserrat-SemiBold',
					fontSize: '16px',
				},
				subtitle1: {
					fontFamily: 'Montserrat-Regular',
					fontSize: '16px',
				},
				subtitle2: {
					fontFamily: 'Montserrat-Regular',
					fontSize: '16px',
				},
				h4: {
					fontFamily: 'Montserrat-SemiBold',
				},
			},

			palette: {
				background: {
					default: '#fff',
					paper: '#FFF',
				},
				primary: {
					main: '#3C3C3C',
					light: '#3C3C3C',
				},
				secondary: {
					main: '#3C3C3C',
					light: '#3C3C3C',
				},
				tertiary: {
					main: '#3C3C3C',
					light: '#3C3C3C',
				},
			},

			overrides: {
				MuiInputBase: {
					input: {
						fontFamily: 'Montserrat-Regular',
						/* fontWeight: 'bold', */
						color: '#3C3C3C',
					},
					label: {
						color: 'white',
					},
					placeholder: {
						color: 'white',
					},
				},

				MuiTableContainer: {
					root: {
						overflowX: 'unset',
					},
				},

				MuiOutlinedInput: {
					root: {
						height: '45px',
						borderColor: 'white',
						borderRadius: 27,
						'&$cssFocused $notchedOutline': {
							borderWidth: 1,
						},
						'&:not($error) $notchedOutline': {
							borderColor: '#3C3C3C',

							// Reset on touch devices, it doesn't add specificity
							'@media (hover: none)': {
								borderColor: 'rgba(0, 0, 0, 0.23)',
							},
						},

						borderWidth: '1px',
						'& :-webkit-autofill': {
							'-webkit-padding-after': '15px',
							'-webkit-padding-before': '18px',
							'-webkit-padding-end': '15px',
							'-webkit-padding-start': '15px',
							'-webkit-background-clip': 'text',
							'-webkit-color': 'white',

							'-webkit-text-fill-color': '#3C3C3C !important',
						},

						'& $notchedOutline': {
							borderColor: 'white',
							borderWidth: 1,
						},
						'&:hover $notchedOutline': {
							borderColor: 'white',
							borderWidth: 1,
						},
						'&$focused $notchedOutline': {
							borderColor: 'white',
							borderWidth: 1,
						},
					},
					focused: {
						borderWidth: '1px',
					},
					notchedOutline: {
						borderWidth: '1px',
					},
					input: {
						'&::placeholder': {
							fontFamily: 'Montserrat-SemiBold',
							textOverflow: 'ellipsis !important',
							color: 'black',
							/* fontWeight: '600', */
							fontSize: '23px',
						},

						borderRadius: '27px',
						height: '10px',
						color: '#3C3C3C',
						fontFamily: 'Montserrat-Thin',
						fontWeight: 'bold',
					},
				},

				MuiTextField: {
					root: {
						margin: '0px 0px 0px 0px',
					},
				},
				MuiInputLabel: {
					outlined: {
						transform: 'translate(14px, 15px) scale(1)',
						'&$shrink': {
							transform: 'translate(14px, -20px) scale(0.8)',
							color: '#3C3C3C',
							fontFamily: 'Montserrat-SemiBold',
						},
						fontFamily: 'Montserrat-Thin',
						fontWeight: 'bold',
						color: '#3C3C3C',
					},
					root: {
						transform: 'translate(10px, 12px) scale(1)',
						'&$shrink': {
							transform: 'translate(10px, -2px) scale(0.8)',
							color: '#3C3C3C',
							fontFamily: 'Montserrat-SemiBold',
						},
						fontFamily: 'Montserrat-Thin',
						fontWeight: 'bold',
						color: '#3C3C3C',
					},
				},
				MuiButton: {
					contained: {
						fontFamily: 'Montserrat-Thin',
						/* fontSize: '0.9rem', */
						textTransform: 'none',
						boxShadow: '0px 0px 0px 0px',
					},

					fontFamily: 'Montserrat-Thin',
					fontWeight: 'bold',
				},
				MuiStepIcon: {
					color: 'red',
					fill: 'red',
					active: {
						color: 'red',
						fill: 'red',
					},
					completed: {
						color: 'red',
						fill: 'red',
					},
				},
			},
		},

		cssVariables: {
			gradient: {
				main: 'linear-gradient(to right top, #cc9b00, #cc9b00);',
			},
		},
		assets: {
			loginSvg: loginSvgAcium,
			smallColoredLogo: smallColoredLogoAcium,
			smallWhiteLogo: smallWhiteLogoAcium,
			backgroundLogo: backgroundLogoAcium,
			tokenImageSvg: tokenImageAcium,
			favicon: faviconAcium,
		},
	},
	aprobank: {
		titleLogin: 'Aprobank',
		name: 'Aprobank - Dashboard do Banco Aprobank',
		description: 'IntegraPAY – Soluções em pagamento para o seu negócio',
		crispId: '6501131f-ae92-4c67-8f05-01e1d5ff2784',
		sidebarRede: 'Rede Aprobank',
		AbaCartoes: false,
		linkApp:
			'https://play.google.com/store/apps/details?id=br.com.pj.aciumbnk',
		linkDePagamento: 'https://banco.aciumbnk.com.br',
		linkPdfTermoContrato:
			'https://sandbox.fitbank.integrapay.com.br/termos/Termos_abertura_conta_digital_AciumBnk.pdf',
		/* mailSupport: 'contato@integrapay.com.br', */

		mainCollors: {
			primary: '#8D1812',
			primaryVariant: '#8D1812',
			secondary: '#161616',
			backgrounds: '#F2F2F2',
			disabledTextfields: '#E9E9E9',
			extratoHome: '#161616',
			primaryGradient:
				'linear-gradient(180deg, rgba(160, 35, 34,1) 15%, rgba(98, 2, 0,1) 100%)',
			secondaryGradient:
				'linear-gradient(180deg, rgba(160, 35, 34,1) 15%, rgba(98, 2, 0,1) 100%)',
			buttonGradient:
				'linear-gradient(180deg, rgba(160, 35, 34,1) 15%, rgba(98, 2, 0,1) 100%)',
			buttonGradientVariant:
				'linear-gradient(180deg, rgba(160, 35, 34,1) 15%, rgba(98, 2, 0,1) 100%)',
			drawerSideBar: '#620200',
		},
		theme: {
			typography: {
				fontFamily: 'Montserrat-Regular',
				/* fontWeight: 'bold', */
				h1: {
					fontFamily: 'Montserrat-SemiBold',
					fontSize: '16px',
				},
				subtitle1: {
					fontFamily: 'Montserrat-Regular',
					fontSize: '16px',
				},
				subtitle2: {
					fontFamily: 'Montserrat-Regular',
					fontSize: '16px',
				},
				h4: {
					fontFamily: 'Montserrat-SemiBold',
				},
			},

			palette: {
				background: {
					default: '#fff',
					paper: '#FFF',
				},
				primary: {
					main: '#8D1812',
					light: '#8D1812',
				},
				secondary: {
					main: '#8D1812',
					light: '#8D1812',
				},
				tertiary: {
					main: '#8D1812',
					light: '#8D1812',
				},
			},

			overrides: {
				MuiInputBase: {
					input: {
						fontFamily: 'Montserrat-Regular',
						/* fontWeight: 'bold', */
						color: '#8D1812',
					},
					label: {
						color: 'white',
					},
					placeholder: {
						color: 'white',
					},
				},

				MuiTableContainer: {
					root: {
						overflowX: 'unset',
					},
				},

				MuiOutlinedInput: {
					root: {
						height: '45px',
						borderColor: 'white',
						borderRadius: 27,
						'&$cssFocused $notchedOutline': {
							borderWidth: 1,
						},
						'&:not($error) $notchedOutline': {
							borderColor: '#8D1812',

							// Reset on touch devices, it doesn't add specificity
							'@media (hover: none)': {
								borderColor: 'rgba(0, 0, 0, 0.23)',
							},
						},

						borderWidth: '1px',
						'& :-webkit-autofill': {
							'-webkit-padding-after': '15px',
							'-webkit-padding-before': '18px',
							'-webkit-padding-end': '15px',
							'-webkit-padding-start': '15px',
							'-webkit-background-clip': 'text',
							'-webkit-color': 'white',

							'-webkit-text-fill-color': '#8D1812 !important',
						},

						'& $notchedOutline': {
							borderColor: 'white',
							borderWidth: 1,
						},
						'&:hover $notchedOutline': {
							borderColor: 'white',
							borderWidth: 1,
						},
						'&$focused $notchedOutline': {
							borderColor: 'white',
							borderWidth: 1,
						},
					},
					focused: {
						borderWidth: '1px',
					},
					notchedOutline: {
						borderWidth: '1px',
					},
					input: {
						'&::placeholder': {
							fontFamily: 'Montserrat-SemiBold',
							textOverflow: 'ellipsis !important',
							color: 'black',
							/* fontWeight: '600', */
							fontSize: '23px',
						},

						borderRadius: '27px',
						height: '10px',
						color: '#8D1812',
						fontFamily: 'Montserrat-Thin',
						fontWeight: 'bold',
					},
				},

				MuiTextField: {
					root: {
						margin: '0px 0px 0px 0px',
					},
				},
				MuiInputLabel: {
					outlined: {
						transform: 'translate(14px, 15px) scale(1)',
						'&$shrink': {
							transform: 'translate(14px, -20px) scale(0.8)',
							color: '#8D1812',
							fontFamily: 'Montserrat-SemiBold',
						},
						fontFamily: 'Montserrat-Thin',
						fontWeight: 'bold',
						color: '#8D1812',
					},
					root: {
						transform: 'translate(10px, 12px) scale(1)',
						'&$shrink': {
							transform: 'translate(10px, -2px) scale(0.8)',
							color: '#8D1812',
							fontFamily: 'Montserrat-SemiBold',
						},
						fontFamily: 'Montserrat-Thin',
						fontWeight: 'bold',
						color: '#8D1812',
					},
				},
				MuiButton: {
					contained: {
						fontFamily: 'Montserrat-Thin',
						/* fontSize: '0.9rem', */
						textTransform: 'none',
						boxShadow: '0px 0px 0px 0px',
					},

					fontFamily: 'Montserrat-Thin',
					fontWeight: 'bold',
				},
				MuiStepIcon: {
					color: 'red',
					fill: 'red',
					active: {
						color: 'red',
						fill: 'red',
					},
					completed: {
						color: 'red',
						fill: 'red',
					},
				},
			},
		},

		cssVariables: {
			gradient: {
				main: 'linear-gradient(to right top, #cc9b00, #cc9b00);',
			},
		},
		assets: {
			loginSvg: loginSvgAprobank,
			smallColoredLogo: smallColoredLogoAprobank,
			smallWhiteLogo: smallWhiteLogoAprobank,
			backgroundLogo: backgroundLogoAprobank,
			tokenImageSvg: tokenImageAprobank,
			favicon: faviconAprobank,
		},
	},
	belobank: {
		titleLogin: 'Belo Bank',
		name: 'Belo Bank - Dashboard do Banco Belo Bank',
		description: 'IntegraPAY – Soluções em pagamento para o seu negócio',
		crispId: '6501131f-ae92-4c67-8f05-01e1d5ff2784',
		sidebarRede: 'Rede Belo Bank',
		AbaCartoes: false,
		linkApp:
			'https://play.google.com/store/apps/details?id=br.com.pj.aciumbnk',
		linkDePagamento: 'https://banco.aciumbnk.com.br',
		linkPdfTermoContrato:
			'https://sandbox.fitbank.integrapay.com.br/termos/Termos_abertura_conta_digital_AciumBnk.pdf',
		/* mailSupport: 'contato@integrapay.com.br', */

		mainCollors: {
			primary: '#AF53FF',
			primaryVariant: '#AF53FF',
			secondary: '#AF53FF',
			backgrounds: '#FBF6FF',
			disabledTextfields: '#E0DFF8',
			extratoHome: '#9545DB',
			primaryGradient:
				'linear-gradient(135deg, rgba(68, 3, 91,1) 15%, rgba(223, 61, 194,1) 100%)',
			secondaryGradient:
				'linear-gradient(0deg, rgba(68, 3, 91,1) 15%, rgba(223, 61, 194,1) 100%)',
			buttonGradient:
				'linear-gradient(270deg, rgba(68, 3, 91,1) 15%, rgba(223, 61, 194,1) 100%)',
			buttonGradientVariant:
				'linear-gradient(135deg, rgba(68, 3, 91,1) 15%, rgba(223, 61, 194,1) 100%)',
			drawerSideBar: '#44035b',
		},
		theme: {
			typography: {
				fontFamily: 'Montserrat-Regular',
				/* fontWeight: 'bold', */
				h1: {
					fontFamily: 'Montserrat-SemiBold',
					fontSize: '16px',
				},
				subtitle1: {
					fontFamily: 'Montserrat-Regular',
					fontSize: '16px',
				},
				subtitle2: {
					fontFamily: 'Montserrat-Regular',
					fontSize: '16px',
				},
				h4: {
					fontFamily: 'Montserrat-SemiBold',
				},
			},

			palette: {
				background: {
					default: '#fff',
					paper: '#FFF',
				},
				primary: {
					main: '#AF53FF',
					light: '#AF53FF',
				},
				secondary: {
					main: '#AF53FF',
					light: '#AF53FF',
				},
				tertiary: {
					main: '#AF53FF',
					light: '#AF53FF',
				},
			},

			overrides: {
				MuiInputBase: {
					input: {
						fontFamily: 'Montserrat-Regular',
						/* fontWeight: 'bold', */
						color: '#AF53FF',
					},
					label: {
						color: 'white',
					},
					placeholder: {
						color: 'white',
					},
				},

				MuiTableContainer: {
					root: {
						overflowX: 'unset',
					},
				},

				MuiOutlinedInput: {
					root: {
						height: '45px',
						borderColor: 'white',
						borderRadius: 27,
						'&$cssFocused $notchedOutline': {
							borderWidth: 1,
						},
						'&:not($error) $notchedOutline': {
							borderColor: '#AF53FF',

							// Reset on touch devices, it doesn't add specificity
							'@media (hover: none)': {
								borderColor: 'rgba(0, 0, 0, 0.23)',
							},
						},

						borderWidth: '1px',
						'& :-webkit-autofill': {
							'-webkit-padding-after': '15px',
							'-webkit-padding-before': '18px',
							'-webkit-padding-end': '15px',
							'-webkit-padding-start': '15px',
							'-webkit-background-clip': 'text',
							'-webkit-color': 'white',

							'-webkit-text-fill-color': '#AF53FF !important',
						},

						'& $notchedOutline': {
							borderColor: 'white',
							borderWidth: 1,
						},
						'&:hover $notchedOutline': {
							borderColor: 'white',
							borderWidth: 1,
						},
						'&$focused $notchedOutline': {
							borderColor: 'white',
							borderWidth: 1,
						},
					},
					focused: {
						borderWidth: '1px',
					},
					notchedOutline: {
						borderWidth: '1px',
					},
					input: {
						'&::placeholder': {
							fontFamily: 'Montserrat-SemiBold',
							textOverflow: 'ellipsis !important',
							color: 'black',
							/* fontWeight: '600', */
							fontSize: '23px',
						},

						borderRadius: '27px',
						height: '10px',
						color: '#AF53FF',
						fontFamily: 'Montserrat-Thin',
						fontWeight: 'bold',
					},
				},

				MuiTextField: {
					root: {
						margin: '0px 0px 0px 0px',
					},
				},
				MuiInputLabel: {
					outlined: {
						transform: 'translate(14px, 15px) scale(1)',
						'&$shrink': {
							transform: 'translate(14px, -20px) scale(0.8)',
							color: '#AF53FF',
							fontFamily: 'Montserrat-SemiBold',
						},
						fontFamily: 'Montserrat-Thin',
						fontWeight: 'bold',
						color: '#AF53FF',
					},
					root: {
						transform: 'translate(10px, 12px) scale(1)',
						'&$shrink': {
							transform: 'translate(10px, -2px) scale(0.8)',
							color: '#AF53FF',
							fontFamily: 'Montserrat-SemiBold',
						},
						fontFamily: 'Montserrat-Thin',
						fontWeight: 'bold',
						color: '#AF53FF',
					},
				},
				MuiButton: {
					contained: {
						fontFamily: 'Montserrat-Thin',
						/* fontSize: '0.9rem', */
						textTransform: 'none',
						boxShadow: '0px 0px 0px 0px',
					},

					fontFamily: 'Montserrat-Thin',
					fontWeight: 'bold',
				},
				MuiStepIcon: {
					color: 'red',
					fill: 'red',
					active: {
						color: 'red',
						fill: 'red',
					},
					completed: {
						color: 'red',
						fill: 'red',
					},
				},
			},
		},

		cssVariables: {
			gradient: {
				main: 'linear-gradient(to right top, #cc9b00, #cc9b00);',
			},
		},
		assets: {
			loginSvg: loginSvgBelobank,
			smallColoredLogo: smallColoredLogoBelobank,
			smallWhiteLogo: smallWhiteLogoBelobank,
			backgroundLogo: backgroundLogoBelobank,
			tokenImageSvg: tokenImageBelobank,
			favicon: faviconBelobank,
		},
	},
	simerbank: {
		titleLogin: 'Simer Bank',
		name: 'Simer - Dashboard do Banco Simer',
		description: 'IntegraPAY – Soluções em pagamento para o seu negócio',
		crispId: '6501131f-ae92-4c67-8f05-01e1d5ff2784',
		sidebarRede: 'Rede Simer Bank',
		AbaCartoes: false,
		linkApp:
			'https://play.google.com/store/apps/details?id=br.com.pj.aciumbnk',
		linkDePagamento: 'https://banco.aciumbnk.com.br',
		linkPdfTermoContrato:
			'https://sandbox.fitbank.integrapay.com.br/termos/Termos_abertura_conta_digital_AciumBnk.pdf',
		/* mailSupport: 'contato@integrapay.com.br', */

		mainCollors: {
			primary: '#205E6B',
			primaryVariant: '#205E6B',
			secondary: '#205E6B',
			backgrounds: '#F4F7F7',
			disabledTextfields: '#E0DFF8',
			extratoHome: '#227081',
			primaryGradient:
				'linear-gradient(180deg, rgba(32, 94, 107,1) 15%, rgba(255, 102, 0,1) 100%)',
			secondaryGradient:
				'linear-gradient(0deg, rgba(32, 94, 107,1) 15%, rgba(255, 102, 0,1) 100%)',
			buttonGradient:
				'linear-gradient(90deg, rgba(32, 94, 107,1) 15%, rgba(255, 102, 0,1) 100%)',
			buttonGradientVariant:
				'linear-gradient(270deg, rgba(32, 94, 107,1) 15%, rgba(255, 102, 0,1) 100%)',
			drawerSideBar: '#205e6b',
		},
		theme: {
			typography: {
				fontFamily: 'Montserrat-Regular',
				/* fontWeight: 'bold', */
				h1: {
					fontFamily: 'Montserrat-SemiBold',
					fontSize: '16px',
				},
				subtitle1: {
					fontFamily: 'Montserrat-Regular',
					fontSize: '16px',
				},
				subtitle2: {
					fontFamily: 'Montserrat-Regular',
					fontSize: '16px',
				},
				h4: {
					fontFamily: 'Montserrat-SemiBold',
				},
			},

			palette: {
				background: {
					default: '#fff',
					paper: '#FFF',
				},
				primary: {
					main: '#205E6B',
					light: '#205E6B',
				},
				secondary: {
					main: '#205E6B',
					light: '#205E6B',
				},
				tertiary: {
					main: '#205E6B',
					light: '#205E6B',
				},
			},

			overrides: {
				MuiInputBase: {
					input: {
						fontFamily: 'Montserrat-Regular',
						/* fontWeight: 'bold', */
						color: '#205E6B',
					},
					label: {
						color: 'white',
					},
					placeholder: {
						color: 'white',
					},
				},

				MuiTableContainer: {
					root: {
						overflowX: 'unset',
					},
				},

				MuiOutlinedInput: {
					root: {
						height: '45px',
						borderColor: 'white',
						borderRadius: 27,
						'&$cssFocused $notchedOutline': {
							borderWidth: 1,
						},
						'&:not($error) $notchedOutline': {
							borderColor: '#205E6B',

							// Reset on touch devices, it doesn't add specificity
							'@media (hover: none)': {
								borderColor: 'rgba(0, 0, 0, 0.23)',
							},
						},

						borderWidth: '1px',
						'& :-webkit-autofill': {
							'-webkit-padding-after': '15px',
							'-webkit-padding-before': '18px',
							'-webkit-padding-end': '15px',
							'-webkit-padding-start': '15px',
							'-webkit-background-clip': 'text',
							'-webkit-color': 'white',

							'-webkit-text-fill-color': '#205E6B !important',
						},

						'& $notchedOutline': {
							borderColor: 'white',
							borderWidth: 1,
						},
						'&:hover $notchedOutline': {
							borderColor: 'white',
							borderWidth: 1,
						},
						'&$focused $notchedOutline': {
							borderColor: 'white',
							borderWidth: 1,
						},
					},
					focused: {
						borderWidth: '1px',
					},
					notchedOutline: {
						borderWidth: '1px',
					},
					input: {
						'&::placeholder': {
							fontFamily: 'Montserrat-SemiBold',
							textOverflow: 'ellipsis !important',
							color: 'black',
							/* fontWeight: '600', */
							fontSize: '23px',
						},

						borderRadius: '27px',
						height: '10px',
						color: '#205E6B',
						fontFamily: 'Montserrat-Thin',
						fontWeight: 'bold',
					},
				},

				MuiTextField: {
					root: {
						margin: '0px 0px 0px 0px',
					},
				},
				MuiInputLabel: {
					outlined: {
						transform: 'translate(14px, 15px) scale(1)',
						'&$shrink': {
							transform: 'translate(14px, -20px) scale(0.8)',
							color: '#205E6B',
							fontFamily: 'Montserrat-SemiBold',
						},
						fontFamily: 'Montserrat-Thin',
						fontWeight: 'bold',
						color: '#205E6B',
					},
					root: {
						transform: 'translate(10px, 12px) scale(1)',
						'&$shrink': {
							transform: 'translate(10px, -2px) scale(0.8)',
							color: '#205E6B',
							fontFamily: 'Montserrat-SemiBold',
						},
						fontFamily: 'Montserrat-Thin',
						fontWeight: 'bold',
						color: '#205E6B',
					},
				},
				MuiButton: {
					contained: {
						fontFamily: 'Montserrat-Thin',
						/* fontSize: '0.9rem', */
						textTransform: 'none',
						boxShadow: '0px 0px 0px 0px',
					},

					fontFamily: 'Montserrat-Thin',
					fontWeight: 'bold',
				},
				MuiStepIcon: {
					color: 'red',
					fill: 'red',
					active: {
						color: 'red',
						fill: 'red',
					},
					completed: {
						color: 'red',
						fill: 'red',
					},
				},
			},
		},

		cssVariables: {
			gradient: {
				main: 'linear-gradient(to right top, #cc9b00, #cc9b00);',
			},
		},
		assets: {
			loginSvg: loginSvgSimer,
			smallColoredLogo: smallColoredLogoSimer,
			smallWhiteLogo: smallWhiteLogoSimer,
			backgroundLogo: '',
			tokenImageSvg: tokenImageSimer,
			favicon: faviconSimer,
		},
	},
}[process.env.REACT_APP_FRONT_APP || 'vbank'];
