export const LOAD_CONTAS = 'LOAD_CONTAS';
export const POST_LOGIN = 'POST_LOGIN';
export const LOAD_CONTA_ID = 'LOAD_CONTA_ID';
export const POST_CONTA = 'POST_CONTA';
export const LOAD_BANCOS = 'LOAD_BANCOS';
export const POST_CONTA_BANCARIA = 'POST_CONTA_BANCARIA';
export const LOAD_CONTA_BANCARIA = 'LOAD_CONTA_BANCARIA';
export const DEL_CONTA_BANCARIA = 'DEL_CONTA_BANCARIA';
export const SET_STATE = 'SET_STATE';
export const GET_APROVAR_CONTA = 'GET_APROVAR_CONTA';
export const GET_FINALIZAR_CADASTRO_CONTA = 'GET_FINALIZAR_CADASTRO_CONTA';
export const LOAD_DOCUMENTO = 'LOAD_DOCUMENTO';
export const DELETE_DOCUMENTO = 'DELETE_DOCUMENTO';
export const POST_DOCUMENTO = 'POST_DOCUMENTO';
export const GET_ENVIAR_DOCUMENTO_IDWALL = 'GET_ENVIAR_DOCUMENTO_IDWALL';
export const LOAD_ALL_CONTAS = 'LOAD_ALL_CONTAS';
export const GET_RESUMO_CONTA_DASHBOARD = 'GET_RESUMO_CONTA_DASHBOARD';
export const GET_GRAFICO_CONTA_LINE_DASHBOARD =
	'GET_GRAFICO_CONTA_LINE_DASHBOARD';
export const GET_GRAFICO_CONTA_BAR_DASHBOARD =
	'GET_GRAFICO_CONTA_BAR_DASHBOARD';
export const GET_CONTAS_EXPORT = 'GET_CONTAS_EXPORT ';
export const POST_CAPTURA = 'POST_CAPTURA';
export const LOAD_COBRANCAS_CARTAO = 'LOAD_COBRANCAS_CARTAO';
export const POST_SPLIT = 'POST_SPLIT';
export const LOAD_BOLETOS = 'LOAD_BOLETOS';
export const LOAD_CARNE = 'LOAD_CARNE';
export const LOAD_LINK_PAGAMENTOS = 'LOAD_LINK_PAGAMENTOS';
export const LOAD_LANCAMENTOS_FUTUROS = 'LOAD_LANCAMENTOS_FUTUROS';
export const LOAD_EXTRATO = 'LOAD_EXTRATO';
export const LOAD_EXPORT_EXTRATO = 'LOAD_EXPORT_EXTRATO';
export const LOAD_ASSINATURAS = 'LOAD_ASSINATURAS';
export const LOAD_PLANOS = 'LOAD_PLANOS';
export const LOAD_HISTORICO_TRANSACAO = 'LOAD_HISTORICO_TRANSACAO';
export const LOAD_EXPORT_TRANSACAO = 'LOAD_EXPORT_TRANSACAO';
export const LOAD_PAGADORES = 'LOAD_PAGADORES';
export const DEL_PAGADOR = 'DEL_PAGADOR';
export const GET_TRANSACAO_TED = 'GET_TRANSACAO_TED';
export const UPDATE_USER_CONTA = 'UPDATE_USER_CONTA';
export const GET_TRANSACAO_PIX = 'GET_TRANSACAO_PIX';
export const GET_CHAVES_PIX = 'GET_CHAVES_PIX';
export const LOAD_TRANSACAO = 'LOAD_TRANSACAO';
export const LOAD_RECEBIVEIS = 'LOAD_RECEBIVEIS';
export const CLEAR_TRANSACAO = 'CLEAR_TRANSACAO';
export const LOAD_HISTORICO_TRANSFERENCIA = 'LOAD_HISTORICO_TRANSFERENCIA';
export const LOAD_EXPORT_TRANSFERENCIA = 'LOAD_EXPORT_TRANSFERENCIA';
export const LOAD_USER_DATA = 'LOAD_USER_DATA';
export const GET_LISTA_ADMINISTRADOR = 'GET_LISTA_ADMINISTRADOR';
export const DELETE_ADMIN = 'DELETE_ADMIN';
export const GET_REENVIAR_TOKEN_USUARIO = 'GET_REENVIAR_TOKEN_USUARIO';
export const POST_PRIMEIRO_ACESSO = 'POST_PRIMEIRO_ACESSO';
export const POST_RECUPERAR_SENHA = 'POST_RECUPERAR_SENHA';
export const POST_SOLICITAR_RESET = 'POST_SOLICITAR_RESET';
export const POST_EMAIL = ' POST_EMAIL';
export const LOAD_PERFIL_TAXA = 'LOAD_PERFIL_TAXA';
export const LOAD_PERFIL_TAXA_ID = 'LOAD_PERFIL_TAXA_ID';
export const POST_PERFIL_TAXA = 'POST_PERFIL_TAXA';
export const PUT_PERFIL_TAXA = 'PUT_PERFIL_TAXA';
export const DELETE_PERFIL_TAXA = 'DELETE_PERFIL_TAXA';
export const POST_VINCULAR_PERFIL_TAXA = 'POST_VINCULAR_PERFIL_TAXA';
export const POST_USER_BLOQUEAR_DESBLOQUEAR = 'POST_USER_BLOQUEAR_DESBLOQUEAR';
export const LOAD_PERMISSAO_GERENCIAR = 'LOAD_PERMISSAO_GERENCIAR';
export const POST_PERMISSAO = 'POST_PERMISSAO';
export const DEL_PERMISSAO = 'DEL_PERMISSAO';
export const POST_AUTH_ME = 'POST_AUTH_ME';
export const LOAD_PERMISSAO = 'LOAD_PERMISSAO';
export const GET_LOGS = 'GET_LOGS';
export const LOAD_LISTAR_PRODUTOS_GIFT_CARD = 'LOAD_LISTAR_PRODUTOS_GIFT_CARD';
export const LOAD_DETALHES_GIFT_CARD = 'LOAD_DETALHES_GIFT_CARD';
export const LOAD_LISTAR_RECARGAS = 'LOAD_LISTAR_RECARGAS';
export const LOAD_DETALHES_RECARGA = 'LOAD_DETALHES_RECARGA';
export const LOAD_LISTA_PRE_CONTAS = 'LOAD_LISTA_PRE_CONTAS';
export const LOAD_LISTA_PRE_CONTA_ID = 'LOAD_LISTA_PRE_CONTA_ID';
export const CLEAR_PRE_CONTA_ID = 'CLEAR_PRE_CONTA_ID';
export const LOAD_TRANSFERENCIA_ID = 'LOAD_TRANSFERENCIA_ID';
export const GET_TRANSACAO_TED_ID = 'GET_TRANSACAO_TED_ID';
export const GET_TRANSACAO_PIX_ID = 'GET_TRANSACAO_PIX_ID';
export const LOAD_PARTNER_TRANSACTIONS = 'LOAD_PARTNER_TRANSACTIONS';
export const POST_BLOQUEAR_DEVICE = 'POST_BLOQUEAR_DEVICE';
export const POST_DESBLOQUEAR_DEVICE = 'POST_DESBLOQUEAR_DEVICE';
export const LOAD_LISTA_DEVICE_BLOQUEADO = 'LOAD_LISTA_DEVICE_BLOQUEADO';
export const POST_DESBLOQUEAR_PERFIL_TAXA = 'POST_DESBLOQUEAR_PERFIL_TAXA';
export const POST_BLACK_LIST_SELFIE = 'POST_BLACK_LIST_SELFIE';
export const GET_BLACKLIST = 'GET_BLACKLIST';
export const POST_BUSCAR_CONTA_CPF = 'POST_BUSCAR_CONTA_CPF';
export const POST_BUSCAR_CONTA_CNPJ = 'POST_BUSCAR_CONTA_CNPJ';
export const SET_CADASTRO_ETAPA_1 = 'SET_CADASTRO_ETAPA_1';
export const SET_CADASTRO_ETAPA_2 = 'SET_CADASTRO_ETAPA_2';
export const SET_CADASTRO_ETAPA_3 = 'SET_CADASTRO_ETAPA_3';
export const SET_CADASTRO_ETAPA_4 = 'SET_CADASTRO_ETAPA_4';
export const SET_CADASTRO_ETAPA_5 = 'SET_CADASTRO_ETAPA_5';
export const POST_ETAPA_1 = 'POST_ETAPA_1';
export const POST_ETAPA_2 = 'POST_ETAPA_2';
export const POST_ETAPA_3 = 'POST_ETAPA_3';
export const POST_ETAPA_4 = 'POST_ETAPA_4';
export const POST_ETAPA_5 = 'POST_ETAPA_5';
export const POST_REENVIAR_TOKEN = 'POST_REENVIAR_TOKEN';
export const POST_VERIFICAR_CONTATO = 'POST_VERIFICAR_CONTATO';
export const POST_VALIDAR_TOKEN = 'POST_VALIDAR_TOKEN';
export const POST_REPRESENTANTE = 'POST_REPRESENTANTE';
export const PUT_REPRESENTANTE = 'PUT_REPRESENTANTE';
export const DEL_REPRESENTANTE = 'DEL_REPRESENTANTE';
export const GET_REPRESENTANTE = 'GET_REPRESENTANTE';
export const DELETE_DOCUMENTO_PRE_CONTA = 'DELETE_DOCUMENTO_PRE_CONTA';
export const GET_DOCUMENTO_PRE_CONTA = 'GET_DOCUMENTO_PRE_CONTA';
export const POST_DOCUMENTO_PRE_CONTA = 'POST_DOCUMENTO_PRE_CONTA';
export const POST_CONTA_PJ = 'POST_CONTA_PJ';
export const POST_ACESSAR_WEB = 'POST_ACESSAR_WEB';
export const GET_ACESSO_WEB = 'GET_ACESSO_WEB';
export const GET_PAGAMENTO_PIX = 'GET_PAGAMENTO_PIX';
export const GET_CONSULTA_CHAVE = 'GET_CONSULTA_CHAVE';
export const POST_PAGAMENTO_PIX = 'POST_PAGAMENTO_PIX';
export const POST_GERAR_QRCODE = 'POST_GERAR_QRCODE';
export const CLEAR_QRCODE_COBRAR = 'CLEAR_QRCODE_COBRAR';
export const POST_LER_QRCODE = 'POST_LER_QRCODE';
export const LOAD_HISTORICO_TED = 'LOAD_HISTORICO_TED';
export const LOAD_BOLETO_LIST = 'LOAD_BOLETO_LIST';
export const LOAD_PAGAMENTOS_LIST = 'LOAD_PAGAMENTOS_LIST';
export const POST_CRIAR_CHAVE = 'POST_CRIAR_CHAVE';
export const DEL_CHAVE = 'DEL_CHAVE';
export const POST_CONFIRMAR_PROPRIEDADE = 'POST_CONFIRMAR_PROPRIEDADE';
export const GET_REENVIAR_CODIGO = 'GET_REENVIAR_CODIGO';
export const LOAD_PAGADORES_USER = 'LOAD_PAGADORES_USER';
export const SET_PAGADOR_ID = 'SET_PAGADOR_ID';
export const DEL_USER_REPRESENTANTE = 'DEL_USER_REPRESENTANTE';
export const POST_USER_REPRESENTANTE = 'POST_USER_REPRESENTANTE';
export const SET_DADOS_BOLETO_GERADO = 'SET_DADOS_BOLETO_GERADO';
export const GET_PAGAMENTO_PIX_APROVAR = 'GET_PAGAMENTO_PIX_APROVAR';
export const GET_PAGAMENTO_APROVAR = 'GET_PAGAMENTO_APROVAR';
export const GET_PAGAMENTO_TED_APROVAR = 'GET_PAGAMENTO_TED_APROVAR';
export const GET_PAGAMENTO_TRANSFERENCIA_APROVAR =
	'GET_PAGAMENTO_TRANSFERENCIA_APROVAR';
export const POST_PAGAMENTO_PIX_APROVAR = 'POST_PAGAMENTO_TED_APROVAR';
export const POST_PAGAMENTO_APROVAR = 'POST_PAGAMENTO_TED_APROVAR';
export const POST_PAGAMENTO_TED_APROVAR = 'POST_PAGAMENTO_TED_APROVAR';
export const POST_PAGAMENTO_TRANSFERENCIA_APROVAR =
	'POST_PAGAMENTO_TED_APROVAR';
export const LOAD_COBRANCAS_RECEBIDAS_WALLET =
	'LOAD_COBRANCAS_RECEBIDAS_WALLET';
export const SET_DADOS_COBRANCA_WALLET = 'SET_DADOS_COBRANCA_WALLET';
export const LOAD_MINHAS_COBRANCAS = 'LOAD_MINHAS_COBRANCAS';
export const LOAD_COBRANCAS_COMPARTILHADAS = 'LOAD_COBRANCAS_COMPARTILHADAS';
export const SET_DADOS_QR_CODE_COBRANCA = 'SET_DADOS_QR_CODE_COBRANCA';
export const GET_FUNCIONARIO = 'GET_FUNCIONARIO';
export const GET_FUNCIONARIO_GRUPO = 'GET_FUNCIONARIO_GRUPO';
export const POST_FUNCIONARIO = 'POST_FUNCIONARIO';
export const POST_FUNCIONARIO_GRUPO = 'POST_FUNCIONARIO_GRUPO';
export const PUT_FUNCIONARIO = 'PUT_FUNCIONARIO';
export const PUT_FUNCIONARIO_GRUPO = 'PUT_FUNCIONARIO_GRUPO';
export const DEL_FUNCIONARIO = 'DEL_FUNCIONARIO';
export const DEL_FUNCIONARIO_GRUPO = 'DEL_FUNCIONARIO_GRUPO';
export const GET_FOLHA_DE_PAGAMENTO = 'GET_FOLHA_DE_PAGAMENTO';
export const GET_FOLHA_DE_PAGAMENTO_SHOW = 'GET_FOLHA_DE_PAGAMENTO_SHOW';
export const GET_FOLHA_DE_PAGAMENTO_APROVAR = 'GET_FOLHA_DE_PAGAMENTO_APROVAR';
export const POST_FOLHA_PAGAMENTO = 'POST_FOLHA_PAGAMENTO';
export const POST_FOLHA_PAGAMENTO_FUNCIONARIO_MULTI =
	'POST_FOLHA_PAGAMENTO_FUNCIONARIO_MULTI';
export const SET_AUTORIZAR_MODAL = 'SET_AUTORIZAR_MODAL';
export const SET_AUTORIZAR_TODOS = 'SET_AUTORIZAR_TODOS';
export const LOAD_LISTA_PRE_CONTA_JURIDICA_ID =
	'LOAD_LISTA_PRE_CONTA_JURIDICA_ID';
export const SET_PRE_CONTA_JURIDICA_ID = 'SET_PRE_CONTA_JURIDICA_ID';
export const DEL_FOLHA_DE_PAGAMENTO_FUNCIONARIO =
	'DEL_FOLHA_DE_PAGAMENTO_FUNCIONARIO';
export const POST_FOLHA_DE_PAGAMENTO_APROVAR =
	'POST_FOLHA_DE_PAGAMENTO_APROVAR';
export const GET_FOLHA_DE_PAGAMENTO_FUNCIONARIO =
	'GET_FOLHA_DE_PAGAMENTO_FUNCIONARIO';
export const SET_HEADER_LIKE = 'SET_HEADER_LIKE';
export const SET_CADASTRAR_LOTE_MODAL = 'SET_CADASTRAR_LOTE_MODAL';
export const POST_FUNCIONARIO_LOTE = 'POST_FUNCIONARIO_LOTE';
export const GET_LISTA_BANNER = 'GET_LISTA_BANNER';
export const POST_BANNER = 'POST_BANNER';
export const DELETE_BANNER = 'DELETE_BANNER';
export const GET_ARQUIVO_LOTE = 'GET_ARQUIVO_LOTE';
export const POST_FOLHA_DE_PAGAMENTO_LOTE = 'POST_FOLHA_DE_PAGAMENTO_LOTE';
export const POST_REENVIAR_FOLHA_DE_PAGAMENTO_LOTE =
	'POST_REENVIAR_FOLHA_DE_PAGAMENTO_LOTE';
export const GET_ARQUIVO_LOTE_FUNCIONARIO = 'GET_ARQUIVO_LOTE_FUNCIONARIO';
export const POST_STATUS_CARTAO_PRE = 'POST_STATUS_CARTAO_PRE';
export const SET_UPDATE_VIEW = 'SET_UPDATE_VIEW';
export const SET_REDIRECIONAR_TRANSFERENCIA = 'SET_REDIRECIONAR_TRANSFERENCIA';
export const SET_REDIRECIONAR_VALOR_TRANSFERENCIA =
	'SET_REDIRECIONAR_VALOR_TRANSFERENCIA';
export const SET_REDIRECIONAR_VALOR_RETIRADA =
	'SET_REDIRECIONAR_VALOR_TRANSFERENCIA';
export const GET_TRANSFERENCIA_EXTRATO = 'GET_TRANSFERENCIA_EXTRATO';
export const POST_REIVINDICAR_PROPRIEDADE = 'POST_REIVINDICAR_PROPRIEDADE';
export const POST_REIVINDICAR_PORTABILIDADE = 'POST_REIVINDICAR_PORTABILIDADE';
export const GET_TED_EXTRATO = 'GET_TED_EXTRATO';
export const GET_PAGAMENTO_CONTA_EXTRATO = 'GET_PAGAMENTO_CONTA_EXTRATO';
export const GET_PAGAMENTO_PIX_EXTRATO = 'GET_PAGAMENTO_PIX_EXTRATO';
export const GET_PAGAMENTO_WALLET_APROVAR = 'GET_PAGAMENTO_WALLET_APROVAR';
export const POST_PAGAMENTO_WALLET_APROVAR = 'POST_PAGAMENTO_WALLET_APROVAR';
export const GET_CARTAO_HISTORICO_TRANSACAO = 'GET_CARTAO_HISTORICO_TRANSACAO';
export const GET_ARQUIVO_LOTE_COMPROVANTE = 'GET_ARQUIVO_LOTE_COMPROVANTE';
export const POST_ENVIAR_COMPROVANTE_FOLHA = 'POST_ENVIAR_COMPROVANTE_FOLHA';
export const GET_FAVORITOS_PIX = 'GET_FAVORITOS_PIX';
export const DELETE_FAVORITO_PIX = 'DELETE_FAVORITO_PIX';
export const GET_FAVORITOS_TED = 'GET_FAVORITOS_TED';
export const DELETE_FAVORITO_TED = 'DELETE_FAVORITO_TED';
export const GET_FAVORITOS_P2P = 'GET_FAVORITOS_P2P';
export const DELETE_FAVORITO_P2P = 'DELETE_FAVORITO_P2P';
export const GET_FAVORITOS_WALLET = 'GET_FAVORITOS_WALLET';
export const DELETE_FAVORITO_WALLET = 'DELETE_FAVORITO_WALLET';
export const POST_PAGAMENTO_BOLETO = 'POST_PAGAMENTO_BOLETO';
export const POST_LINK_PAGAMENTOS = 'POST_LINK_PAGAMENTOS';
export const LOAD_PAGADOR_ID = 'LOAD_PAGADOR_ID';
export const LOAD_LINK_PAGAMENTOS_ID = 'LOAD_LINK_PAGAMENTOS_ID';
export const PUT_PAGADOR = 'PUT_PAGADOR';
export const POST_PAGADOR = 'POST_PAGADOR';
export const POST_COBRANCA_CARTAO = 'POST_COBRANCA_CARTAO';
export const PUT_ASSINATURA = 'PUT_ASSINATURA';
export const DEL_ASSINATURA = 'DEL_ASSINATURA';
export const DEL_PLANO = 'DEL_PLANO';
export const LOAD_PLANO_ID = 'LOAD_PLANO_ID';
export const POST_PLANO = 'POST_PLANO';
export const PUT_PLANO = 'PUT_PLANO';
export const POST_ASSINATURA = 'POST_ASSINATURA';
export const POST_CARTAO_PAGADOR = 'POST_CARTAO_PAGADOR';
export const GET_MINHAS_ASSINATURAS = 'GET_MINHAS_ASSINATURAS';
export const GET_MINHAS_TAXAS = 'GET_MINHAS_TAXAS';
export const POST_ASSINATURA_PLAN = 'POST_ASSINATURA_PLAN';
export const DEL_PLANO_ASSINATURA = 'DEL_PLANO_ASSINATURA';
export const DEL_PLANO_ASSINATURA_EC = 'DEL_PLANO_ASSINATURA_EC';
export const GET_EXPORTACOES_SOLICITADAS = 'GET_EXPORTACOES_SOLICITADAS';
export const GET_EXPORT_DOWNLOAD = 'GET_EXPORT_DOWNLOAD';
export const GET_TERMINAIS_POS = 'GET_TERMINAIS_POS';
export const GET_TERMINAL_POS = 'GET_TERMINAL_POS';
export const GET_TERMINAL_POS_TRANSACTIONS = 'GET_TERMINAL_POS_TRANSACTIONS';
export const DEL_TERMINAL_POS = 'DEL_TERMINAL_POS';
export const PUT_TERMINAL_POS = 'PUT_TERMINAL_POS';
export const POST_ACEITAR_TERMO_ABERTURA = 'POST_ACEITAR_TERMO_ABERTURA';
export const POST_TERMINAL_POS = 'POST_TERMINAL_POS';
